.app {
    &__user {
        //padding-left: 0px;
        //display: flex;
        //align-items: center;
        cursor: pointer;
        text-decoration: none;
        //border-right: 1px solid black;

        &__avatar {
            width: 40px;
            height: 40px;
            border-radius: $border-radius;
            background-color: $bg-color;
            color: $body-color;
            display: inline-block;
            margin-top: 6px;
            //margin-right: 10px;
            //overflow: hidden;

            img {
                width: 100%;
            }

            i {
                font-size: 20px;
                margin: 10px 10px 0 12px;
            }
        }

        &__name {
            color: black;
            font-weight: 500;
        }

        &__email {
            padding-top: 3px;
            //opacity: 0.8;
            //font-size: 80%;
            color: $body-color;
            //font-weight: bold;
        }

        &__options {
            .dropdown-item {
                font-size: 14px;
            }
        }
    }
}
