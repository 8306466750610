.app {
    &__search {
        font-size: 14px;
        display: block;
        position: relative;
        width: 100%;
        max-width: 750px;

        &__input {
            border: none;
            height: 50px;
            background-color: $bg-color;
            padding: 0 60px 0 20px;
            width: 100%;
            border-radius: $border-radius;
        }

        &__button {
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
            line-height: 34px;
            font-size: 26px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $primary;
        }
    }
}
