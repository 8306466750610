$padding: 20px;
$leftNavWith: 180px;
$headerHeight: 80px;
$leftContentPadding: $leftNavWith + $padding;
$topContentPadding: $headerHeight + $padding;

$input-border-radius: 5px;
$border-radius: 10px;

$box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.05);
$box-shadow-light: 0 0 10px 0 rgba(0, 0, 0, 0.05);

$bg-color: #F7F6FB;
$body-color: #050B20;
//$border-color: #F7F6FB;
$primary: #2F4BAF;
$blue: #2F4BAF;
$green: #65CB34;
$orange: #FFB914;

$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1500px
);

$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1400px
);
