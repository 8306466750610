.orders {
    &-filter {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px $padding;
        background-color: white;
        border-radius: $border-radius;
        margin-bottom: 20px;
        box-shadow: $box-shadow;

        form {
            display: flex;
            align-items: center;
        }

        .form-group {
            margin-bottom: 0;
        }

        &__state {
            width: 230px;
            margin-right: 20px;
        }

        &__dates {
            display: flex;
            align-items: center;
            margin-right: 20px;

            input {
                max-width: 120px;
            }

            .input-group {

                &-append,
                &-prepend {
                    border: 1px solid $border-color;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &-append {
                }

                &-prepend {
                    border-radius: $input-border-radius 0 0 $input-border-radius;
                }

                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }

        &__button {
            font-size: 26px;
            color: $primary;
        }
    }

    &-list {
        padding: 0;
        background-color: white;
        border-radius: $border-radius;
        box-shadow: $box-shadow;
    }
}