
@mixin button-inverse-variant($color, $color-hover: $white) {

    $opacity: 0.2;
    @if $color == #ffc107 {
        $color: darken($color, 10%);
    }

    color: $color;
    background-color: rgba($color, $opacity);
    background-image: none;
    border-color: rgba($color, 0);
    //@include hover {
    //    color: $color-hover;
    //    background-color: $color;
    //    border-color: $color;
    //}

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &.active,
    &:active,
    .show > &.dropdown-toggle {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
    }
}

@mixin button-inverse-outline-variant($color, $color-hover: $white) {
    color: $color;
    background-image: none;
    background: transparent;
    border-color: rgba($color, 0.2);
    //@include hover {
    //    color: $color;
    //    background-color: rgba($color, 0.2);
    //    border-color: rgba($color, 0.2);
    //}

    &.focus,
    &:focus {
        box-shadow: 0 0 0 3px rgba($color, .5);
    }

    &.disabled,
    &:disabled {
        color: $color;
        background-color: transparent;
    }

    &.active,
    &:active,
    .show > &.dropdown-toggle {
        color: $color-hover;
        border-color: $color;
    }
}

@mixin badge-inverse-variant($color, $color-hover: $white) {
    color: $color;
    background-color: rgba($color, 0.2);
    background-image: none;
    border-color: rgba($color, 0);
}
