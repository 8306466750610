.app {
    &__modules {
        &__nav {
            margin: 0;
            padding: 0;
            list-style-type: none;

            &__item {

            }

            &__link {
                width: $leftNavWith;
                height: $leftNavWith;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $primary;
                position: relative;
                font-size: 30px;
                text-decoration: none !important;
                transition: all .2s;

                &--active,
                &:hover {
                    background-color: $primary;
                    color: white;

                    &:after {
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 0;
                        width: 10px;
                        height: 20px;
                        margin-top: -10px;
                        background-color: $bg-color;
                        border-radius: 50px 0 0 50px;
                    }
                }

                .indicator {
                    position: absolute;
                    top: 50%;
                    left: 5px;
                    margin-top: -9px;
                }
            }
        }
    }
}