:root {
    --app-green: #4CAF50;
    --app-green-focus: #4e8e52;
    --app-red: #EF4444;
    --app-red-focus: #B91C1C;
    --app-blue: #2F4BAF;
}

.p-disabled, .p-component:disabled {
    opacity: 0.7;
}

a:not([href]):not([class]) {
    &,
    &:hover {
        color: #2F4BAF;
        cursor: pointer;
    }
}

.dropdown {
    &-menu {
        border-color: $border-color;
    }

    &-toggle {
        &::after {
            border-top-color: $border-color;
        }
    }
}

p-dropdown.disabled >div {
    opacity: 0.38;
}

div.p-card-title-custom {
    border-bottom: 1px solid $border-color;
    padding: 12px 24px 8px 24px;
    margin-bottom: 0;
}

div.p-card-title-no-border {
    border: 0;
    padding: 6px 24px 0px 12px;
    margin-bottom: 0;
}

div.p-password {
    display: block;
    input {
        width: 100%;
    }
}

div.p-dropdown {
    width: 100%;
}

span.p-checkbox-icon::before {
    box-sizing: initial;
}

.p-card .p-card-body {
    padding: 0;
}

.p-fluid .p-button-icon-only {
    //width: 2.4rem;
}

//.swal2-styled.swal2-confirm {
//    background-color: #28a745!important;
//    border-color: #28a745!important;
//}
//.swal2-styled.swal2-confirm:focus {
//    box-shadow: none !important;
//}
//


//.p-selectbutton .p-button {
//    background: white;
//    color: #6c757d;
//}

p-inputNumber.ng-valid .p-inputtext:enabled:focus, .p-inputtext.ng-valid:enabled:focus {
    box-shadow: inset 0 0 0 1px #52a32f, inset 0 0 0 1px #52a32f, inset 0 0 0 1px #52a32f, inset 0 0 0 1px #52a32f;
    border-color: #52a32f;
}


.p-tabview .p-tabview-panels {
    padding: 0;
}


.p-selectbutton .p-button.p-highlight, .p-selectbutton .p-button:focus.p-highlight {
    background: #4CAF50;
    border-color: #4CAF50;
    color: #ffffff;
}

p-selectButton.normal .p-selectbutton .p-button.p-highlight, p-selectButton.normal .p-selectbutton .p-button:focus.p-highlight {
    background: var(--app-blue);
    border-color: var(--app-blue);
    color: #ffffff;
}


p-selectButton.on-off-select-button .p-selectbutton .p-button.p-highlight:first-of-type, p-selectButton.on-off-select-button .p-selectbutton .p-button:focus.p-highlight:first-of-type {
    background: var(--app-red);
    border-color: var(--app-red);
    color: #ffffff;
}


p-toast >div {
    z-index: 999999!important;
}


.p-button.p-button-success, .p-buttonset.p-button-success > .p-button, .p-splitbutton.p-button-success > .p-button {
    color: #ffffff;
    background: var(--app-green);
    border: 0 none;
}

.p-button.p-button-success:enabled:hover, .p-buttonset.p-button-success > .p-button:enabled:hover, .p-splitbutton.p-button-success > .p-button:enabled:hover {
    background:var(--app-green-focus);
    color: #ffffff;
    border-color: transparent;
}


.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
    box-shadow: inset 0 4px 0 0 #007ad9;
}
.p-datatable .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
    box-shadow: inset 0 -4px 0 0 #007ad9;
}

p-fieldset {
    legend {
        float: initial;
        width: initial;
        margin-left: 15px;

        a {
            text-decoration: none;
            font-size: 1.3rem;
        }
    }
}

.p-fluid .auto-width {
    width: auto;
}

p-autoComplete {
    span {
        width: 100%;
        input {
            width: 100%;
        }
    }
}
//
//.p-fluid .p-button-icon-only {
//    width: initial;
//}

.inline-flex {
    display: inline-flex;
}

span.element-type {
    p {
        margin: 0;
    }
}

.no-p-margin {
    p {
        margin: 0;
    }
}


.visibility-hidden {
    visibility: hidden;
}


.p-progressbar {
    height: 20px;
    .p-progressbar-label {
        line-height: 20px;
    }
}

p-inputNumber.input-number-full {
    span {
        display: contents;
        input {
            width: 100%;
        }
    }
}

p-dropdown .p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-inputgroup .p-inputwrapper>.p-component {
    width: 100% !important;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.pi-question-circle {
    cursor: pointer;
}


p-fieldset.danger {
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
        color: red;
    }
}

p-fieldset.highlight {
    .p-fieldset.p-fieldset-toggleable .p-fieldset-legend a {
        color: darkgreen;
    }
}


a.p-button {
    text-decoration: none;
}


p-editor .ql-editor p {
    margin: 13px 0;
}

.p-datepicker table th {
    text-align: center;
}

.swal2-container.swal2-center{
    z-index: 99999;
}

span.image-tooltip {
    p-image {
        & > span > img {
            display: none;

        }
        & > span {

        }

        .p-image-preview-indicator {
            &:hover {

            }
            opacity: 1;
            position: initial;
            transition: none;
            color: black;
        }
        .p-image-preview-container:hover > .p-image-preview-indicator {
            background-color: inherit;
        }
    }
}


.p-component[readonly] {
    opacity: 0.38;
    pointer-events: none;
}


.p-chips .p-chips-multiple-container li.p-chips-token {
    flex: inherit;
    word-break: break-all;
    margin-top: 3px;
}

div.NgxEditor {
    overflow-y: auto;
    max-height: 300px;
}

a {
    text-decoration: none;
}


.p-inputtext.ng-touched.ng-invalid, p-dropdown.ng-touched.ng-invalid > .p-dropdown {
    border-color: #B00020;
}

.p-inputtext:enabled:focus.ng-invalid.ng-touched {
    box-shadow: inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020;
}

p-selectbutton.ng-touched.ng-invalid .p-button {
    box-shadow: inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020, inset 0 0 0 1px #b00020;

}


.p-info {
    color: rgb(1, 87, 155);
}

div.p-fileupload-highlight {
    background: rgba(211, 211, 211, 0.29) !important;
}

.mat-vertical-stepper-content:not(.mat-vertical-stepper-content-inactive) {
    overflow: visible;
}


.height-100 {
    height: 100%;
}

.p-card .p-card-content {
    padding-top: 0;
}


google-map {
    display: block;
}

.p-multiselect.p-multiselect-chip .p-multiselect-token {
    margin-right: 3px;
}

label.disabled {
    opacity: 0.7;
}

.min-width-50 {
    min-width: 50px;
}
