/* inverse buttons */
@each $color, $value in $theme-colors {
    .btn-inverse-#{$color} {
        @include button-inverse-variant($value);
    }
}

/* Inverse Outlined Buttons */
@each $color, $value in $theme-colors {
    .btn-inverse-outline-#{$color} {
        @include button-inverse-outline-variant($value);
    }
}

.btn {
    &--rounded {
        border-radius: 100px;
    }
}