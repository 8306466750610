.app {
    &__left-nav {

        position: fixed;
        top: 0;
        left: 0;
        width: $leftNavWith;
        background-color: #2f4050;
        height: 100%;
        z-index: 2;
        box-shadow: $box-shadow;
        overflow-x: hidden;

        nav li.active > a {
            color: white;
        }
        a {
            cursor: pointer;
        }

        .app-nav {
            margin-bottom: 0;
            padding-left: 0; // Override default ul/ol
            list-style: none;
            li a {
                text-decoration: none;
                color: #a7b1c2;
                &:hover, &:focus {
                    color: white;
                    text-decoration: none;
                    background-color: #293846;
                }
            }
            li {
                &:hover, &:focus {
                    color: white;
                    text-decoration: none;
                    background-color: #293846;
                }
            }
            > li {
                position: relative;
                display: block;

                > a {
                    position: relative;
                    display: block;
                    padding: 14px 20px 14px 25px;
                }
            }
            > li.active {
                border-left: 4px solid #19aa8d;
                background: #293846;
            }
            ul.nav-second-level {
                //display: none;
                //padding-left: 52px;
                li a {
                    display: inline-block;
                    padding: 7px 10px 7px 52px;
                }
            }
            ul.collapse.in {
                display: block;
            }
        }


    }

    &__logo {
        border-bottom: 4px solid #19aa8d;
        height: 80px;
        a {
            //background-color: white;
            display: flex;
            height: 80px;
            align-items: center;
            //justify-content: center;
            //width: 80px;
            //max-height: 80px;
            //border-bottom: 1px solid $bg-color;
            //border-right: 1px solid $bg-color;
            img {
                max-width: 100px;
                max-height: 50px;
            }
        }
    }

}
