.app {
    &__header {
        position: absolute;
        top: 0;
        left: $leftNavWith;
        z-index: 3;
        padding: 10px $padding 10px $padding;
        background-color: white;
        width: calc(100% - #{$leftNavWith});
        height: $headerHeight;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__left {
            flex-grow: 1;
        }

        &__right {
            display: flex;
            align-items: center;
        }
    }
    &__header-collapsed {
        width: 100%;
        left: 0;
    }
}
