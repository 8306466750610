// bootstrap

* {
  box-sizing: border-box;
}
html {
    font-size: 13px !important;
}

// ---------------------------------------------------
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "assets/icomoon/style";

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1500px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1400px
);

@import "../node_modules/bootstrap/scss/bootstrap";

// utilities
// ---------------------------------------------------
@import "assets/scss/utilities/variables";
@import "assets/scss/utilities/mixins";


// base
// ---------------------------------------------------
@import "assets/scss/base/base";
@import "assets/scss/base/buttons";
@import "assets/scss/base/badges";
@import "assets/scss/base/form-controls";
@import "assets/scss/base/colors";
@import "assets/scss/base/font-sizes";
@import "assets/scss/base/tables";
@import "assets/scss/base/overrides";
@import "assets/scss/base/animations";


// layout
// ---------------------------------------------------
@import "assets/scss/layout/left_nav";
@import "assets/scss/layout/header";
@import "assets/scss/layout/content";
@import "assets/scss/layout/auth";

// components
// ---------------------------------------------------
@import "assets/scss/components/orders";
@import "assets/scss/components/modules";
@import "assets/scss/components/indicator";
@import "assets/scss/components/search";
@import "assets/scss/components/notifications";
@import "assets/scss/components/user";
@import "assets/scss/components/order-row";
@import "assets/scss/components/togglers";
@import "assets/scss/components/fc-modal";
@import "assets/scss/components/cards";
@import "assets/scss/components/breadcrumb";
@import "assets/scss/components/incrementer";
@import "assets/scss/components/order-detail";

$theme-colors: (
        primary: $primary
);

.p-menu-overlay {
    margin-top: 5px;
}

//html {
//    scroll-behavior: unset !important;
//}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.p-card--fixed {
    .p-card {
        padding-top: 70px;

        .p-card-header {
            position: fixed;
            top: 0;
            background: white;
            z-index: 999;
        }
    }

}

.p-card--fixed-content {
    position: fixed;
    top: 99px;
}


.p-dialog .p-dialog-content {
    padding-bottom: 0;
}
