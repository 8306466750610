.order-detail {

    &__title {
        font-size: 24px;
        display: block;
        margin: 0 0 20px 0;
    }

    address {
        padding: 15px;
        border: 2px solid $border-color;
        border-radius: $border-radius;
    }

    .address-row {
        display: block;

        &.full-name {
            margin-bottom: 10px;
        }

        &.country {
            margin-top: 10px;
        }
    }

    .phone,
    .email {
        display: block;
    }

    .contact-info {
        border-collapse: collapse;

        td:first-child {
            padding-right: 10px;
        }
    }

    &__items {
        margin-top: $modalPadding;

        table {
            margin: 0 0 (-$modalPadding) 0;
            width: calc(100% + (2 * #{$modalPadding}));
        }

        td {
            vertical-align: middle;
            padding: 10px 15px;
        }

        th {
            padding: 10px 15px;
        }

        td,
        th {
            font-size: 14px;
            text-align: left;
        }

        .date,
        .price {
            text-align: right;
        }
    }
}
