.card {
    border-radius: $border-radius;
    border: none;
    box-shadow: $box-shadow-light;

    &-body {
        padding: $padding;
    }
}

p-card.hide-content {
    div.p-card-content {
        display: none;
    }
}
