
.animated {
    animation-duration: 1.5s;
    animation-fill-mode: both;
    animation-iteration-count: 1;
}


@keyframes wobble {
    0% {transform: translateX(0%);}
    15% {transform: translateX(-15%) rotate(-5deg);}
    30% {transform: translateX(10%) rotate(3deg);}
    45% {transform: translateX(-5%) rotate(-3deg);}
    60% {transform: translateX(5%) rotate(2deg);}
    75% {transform: translateX(-3%) rotate(-1deg);}
    100% {transform: translateX(0%);}
}
.wobble {
    animation-name: wobble;
}

@keyframes wobble-small {
    0% {transform: translateX(0%);}
    15% {transform: translateX(-0.5%) rotate(-0deg);}
    30% {transform: translateX(0.5%) rotate(0deg);}
    45% {transform: translateX(-0.5%) rotate(-0deg);}
    60% {transform: translateX(0.5%) rotate(0deg);}
    75% {transform: translateX(-0.5%) rotate(-0deg);}
    100% {transform: translateX(0%);}
}
.wobble-small {
    animation-name: wobble-small;
}
