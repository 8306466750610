/* inverse badges */
@each $color, $value in $theme-colors {
    .badge-inverse-#{$color} {
        @include badge-inverse-variant($value);
    }
}
.custom-badge {
    padding-left: 5px;
    padding-right: 5px;
    display: block;
    text-align: center;
    border-radius: 4px;
}

.custom-badge-alt {
    padding-left: 5px;
    padding-right: 5px;
    display: inline-block;
    border-radius: 4px;
}

.custom-badge--1 {
    color: black;
    background-color: #F7D000;
}

.custom-badge-0 {
    color: white;
    background-color: #04B100;
}

.custom-badge-1 {
    color: white;
    background-color: #0087DC;
}

.custom-badge-2, .custom-badge-danger{
    color: white;
    background-color: #D0001D;
}

.custom-badge-3{
    color: white;
    background-color: #550052;
}

.custom-badge-created, .custom-badge-payment_method_chosen, .custom-badge-public_app, .custom-badge-public_web, .custom-badge-license {
    background-color: #b3e5fc;
    color: #23547b;
}

.custom-badge-not_found {
    background-color: #eccfff;
    color: #694382;
}

.custom-badge-paid, .custom-badge-public, .custom-badge-role, .custom-badge-success {
    background-color: #c8e6c9;
    color: #256029;
}

.custom-badge-gray {
    color: #ffffff;
    background-color: #858585;
}

.custom-badge-hidden {
    color: black;
    background-color: #c9c9c9;
}

.custom-badge-refunded, .custom-badge-timeouted, .custom-badge-canceled, .custom-badge-cancelled {
    background-color: #ffcdd2;
    color: #c63737;
}
