.app {
  &__notifications {
    cursor: pointer;
    width: 50px;
    height: 50px;
    border-radius: $border-radius;
    background-color: $bg-color;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 34px;
    font-size: 26px;
    position: relative;
    text-decoration: none !important;
    color: $primary;

    &:after {
      display: none;
    }

    .indicator {
      position: absolute;
      top: -9px;
    }
  }
}
