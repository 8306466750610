.checkbox {
    cursor: pointer;

    &:hover {
        input ~ .indicator {
            box-shadow: inset 0 0 3px 3px rgba(0, 0, 0, 0.1);
        }
    }

    input {
        display: none;

        & ~ .indicator {
            width: 30px;
            height: 30px;
            border: 1px solid #CCCCCC;
            border-radius: 3px;
            display: inline-flex;
            transition: all .2s ease-in;
            align-items: center;
            justify-content: center;

            &:after {
                content: "";
                width: 14px;
                height: 14px;
                border-radius: 3px;
                background-color: transparent;
                transition: background-color .2s;
            }
        }

        &:checked ~ .indicator {
            //border-color: #aec0ff;

            &:after {
                background-color: $green;
            }
        }
    }
}

.order-pack-toggler {
    cursor: pointer;

    input {
        display: none;

        & ~ .indicator {
            display: block;
            width: 50px;
            height: 50px;
            background: url("/assets/images/packed-0.svg") center bottom / auto 40px no-repeat;
        }

        &:checked ~ .indicator {
            background: url("/assets/images/packed-1.svg") center bottom / auto 34px no-repeat;
        }
    }
}

.drop-box {
    padding: 10px 10px;
    margin: 0;
    //border: solid 1px #ccc;
    border-radius: 3px;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    background: white;
    border-bottom: solid 1px #ccc;
}

.drop-box.invalid {
    background: #ff000033;
    margin-top: 2px;
}

.drop-box.active {
    background: #4CAF5044;
    margin-top: 2px;
}

div.drop-list .drop-box:last-of-type {
    border-bottom: none;
}

.drop-box.element-invalid {
    border-color: #D32F2F;
    border-width: 3px;
    span.element-type {
        color: #D32F2F;
        font-weight: bold;
    }
}

.example-handle {
    cursor: move;
    color: #ccc;
}


.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 0.5);
}

.drop-list.cdk-drop-list-dragging .drop-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 0.5);
}
