body, html {
    margin: 0;
    padding: 0;
    //font-size: 16px;
    //font-weight: 500;
}

body {
    background-color: $bg-color;
    font-family: 'Poppins', sans-serif;
    color: $body-color;
}

.app {
    min-height: 100vh;

    &--blurred {
        & > *:not(.fc-modal) {
            filter: blur(1px);
        }
    }

    &--fcm-opened {
        overflow: hidden;
    }
}

a {
    color: $primary;

    &:hover {
        color: darken($primary, 10%);
    }
}

h1 {
    font-size: 26px;
    margin: 0 0 30px 0;
}

h2 {
    font-size: 22px;
    margin: 0 0 20px 0;
}

[class^="icon-"], [class*=" icon-"] {
    font-weight: 100 !important;
}

[data-title]:hover {
    position: relative;

    &:before {
        content: "" attr(data-title) "";
        position: absolute;
        right: 50%;
        bottom: 100%;
        transform: translate(50%, 0);
        padding: 7px 12px;
        background-color: #182142;
        color: white;
        border-radius: 100px;
        transition: .2s ease;
        font-size: 12px;
        font-family: Poppins, sans-serif;
        white-space: nowrap;
    }

    &::after {
        position: absolute;
        bottom: calc(100% - 6px);
        left: 50%;
        margin-left: -10px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 10px 0 10px;
        border-color: #182142 transparent transparent transparent;
        content: "";
        transition: .2s ease;
    }
}

@font-face {
    font-family: voucher;
    src: url('../../fonts/OpenSans-bold.ttf') format("opentype");
}

@font-face {
    font-family: voucher-bebas;
    src: url('https://api.getoutfun.com/fonts/voucher/Bebas.ttf') format("opentype");
}

@font-face {
    font-family: voucher-dancingScript;
    src: url('https://api.getoutfun.com/fonts/voucher/DancingScript.ttf') format("opentype");
}

@font-face {
    font-family: voucher-openSans;
    src: url('https://api.getoutfun.com/fonts/voucher/OpenSans.ttf') format("opentype");
}

@font-face {
    font-family: voucher-pacifico;
    src: url('https://api.getoutfun.com/fonts/voucher/Pacifico.ttf') format("opentype");
}

@font-face {
    font-family: voucher-roboto;
    src: url('https://api.getoutfun.com/fonts/voucher/Roboto.ttf') format("opentype");
}
