$modalPadding: 1.5 * $padding;

.fc-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;

    &__content {
        position: absolute;
        top: 2 * $padding;
        left: 2 * $padding;
        width: calc(100% - (4 * #{$padding}));
        height: calc(100% - (4 * #{$padding}));
        padding: $modalPadding $modalPadding 0 $modalPadding;
        background-color: white;
        z-index: 3;
        border-radius: $border-radius;
        overflow: auto;
    }

    &__title {
        font-size: 30px;
        margin: 0 0 30px 0;
    }

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.05);
    }

    &__tabs {
        margin: 0 0 (-$modalPadding);

        &__nav {
            margin: 0;
            padding: 0 $modalPadding;
            list-style-type: none;
            border-bottom: 3px solid $border-color;
            display: flex;

            &__item {

            }

            &__link {
                display: flex;
                align-items: center;
                padding: 15px 15px;
                margin: 0 0 -3px 0;
                text-transform: uppercase;
                color: $gray-400;
                font-size: 16px;
                text-decoration: none !important;
                transition: all .2s;
                font-weight: 600;

                i {
                    margin-right: 8px;
                }

                &:hover {
                    color: $primary;
                }

                &--active {
                    color: $primary;
                    border-bottom: 3px solid $primary;
                }
            }
        }

        &__content {

            &__item {
                padding: $modalPadding;
                display: none;

                &--active {
                    display: block;
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        width: 70px;
        height: 70px;
        line-height: 70px;
        font-size: 30px;
        text-align: center;
        color: $gray-500;
    }
}

[data-fcm-close] {
    cursor: pointer;
}
