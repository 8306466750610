body {

    div.full-page-wrapper {
        width: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url("/assets/images/glorynight-final-min.jpg");
        background-position: center center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
    }

    div.auth-form {
        background-color: white;
        opacity: 0.90;
        padding: 30px 30px 10px 30px;
        border-radius: 4px;
        box-shadow: 0 -25px 37.7px 11.3px rgba(8, 143, 220, .07);
    }
}
