.order-row {
    display: flex;
    align-items: center;
    font-size: 14px;

    &:not(:last-child) {
        border-bottom: 1px solid $border-color;
    }

    label {
        margin: 0;
    }

    &__cell {
        padding: 10px 15px;

        &:last-child {
            padding-right: $padding;
        }

        &--checkbox {
            padding: 0;
            align-self: stretch;
            display: flex;

            label {
                padding: 0 $padding;
                flex: 1;
                display: flex;
                align-items: center;
            }

            & + * {
                padding-left: 0;
            }
        }

        &--pack {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0;
        }

        &--space {
            flex-grow: 1;
        }

        &--actions {
            display: flex;
            align-items: center;
        }
    }

    &__value {
        &--small {
            opacity: 0.6;
            //font-size: 80%;
        }

        &--big {
            font-weight: 600;
            font-size: 120%;
        }
    }

    &__address-validation {
        display: flex;
        align-items: center;
    }

    &-action {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: $bg-color;
        transition: all .2s;
        text-decoration: none !important;
        color: $primary;
        font-size: 20px;

        &:not(:first-child) {
            margin-left: 5px;
        }

        &:hover {
            background-color: $primary;
            color: white;
        }
    }
}

.order {
    &-shipping-selection {
        &__selected {
            cursor: pointer;

            img {
                max-width: 100px;
                max-height: 35px;
            }
        }

        &__options {
            display: none;
        }
    }
}