.indicator {
    &--active {
        width: 18px;
        height: 18px;
        background-color: rgba(red($orange), green($orange), blue($orange), 0.2);
        z-index: 2;
        border-radius: 50%;

        &:before {
            position: absolute;
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
            content: "";
            background-color: $orange;
            z-index: 1;
            border-radius: 50%;
        }
    }
}