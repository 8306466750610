.package-incrementer {
    display: flex;
    align-items: center;
    font-size: 18px;

    &__plus,
    &__minus {
        width: 30px;
        height: 30px;
        border: 1px solid $border-color;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            border-color: $primary;
            background-color: $primary;
            color: white;
        }
    }

    &__quantities {
        margin: 0 15px;
    }

    &__packed {
        color: $green;
    }

    &__quantity {
        color: $primary;
    }
}