.table {
    &-striped {
        tbody {
            tr {
                &:nth-of-type(odd) {
                    background-color: $bg-color;
                }
            }
        }
    }
}

.p-datatable {
    .p-datatable-tbody > tr.danger {
        background: #ff000033;
    }
    .p-datatable-tbody > tr.success {
        background: #4CAF5044;
    }
    .p-datatable-tbody > tr.secondary {
        //background: #075abd;
        //color: #ffffff;
        background: rgba(199, 199, 199, 0.27);
    }
    .p-datatable-tbody > tr.inactive {
        background: rgba(199, 199, 199, 0.27);
        opacity: 0.8;
    }
    .p-datatable-tbody > tr.inactive.cdk-drag-placeholder {
        opacity: 0;
    }
}

.p-datatable.p-datatable-sm .p-datatable-thead > tr > th, .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td  {
    padding: 0.375rem 0.7rem;
}
