.form-control {
    font-size: 14px;
    border-color: $border-color;
    font-weight: 500;
}

select.form-control {
    color: $primary;
    appearance: none;
    border: 1px solid $border-color;
    padding-right: 50px;
    background: transparent url("/assets/images/select-arrow.svg") right center no-repeat;
}

.form-label {
    margin-bottom: 0;
}

label {
    font-weight: bold;
}

span.p-calendar, div.p-multiselect {
    width: 100%;
}

//
//.p-multiselect {
//    min-width: 10rem;
//    width: 100%;
//}

p-fieldset {
    display: block;
    position: relative;
    .add-element {
        background-color: white;
        position: absolute;
        top:0;
        right: 20px;
    }
}

span.element-invalid {
    font-weight: bold;
    color: #D32F2F;
}



.drop-list--borders {
    //width: 500px;
    max-width: 100%;
    border: solid 1px #ccc;
    min-height: 60px;
    display: block;
    background: white;
    border-radius: 4px;
    box-sizing: border-box;
    //overflow: hidden;

    .drop-box {
        border: solid 1px #ccc;
    }

}
