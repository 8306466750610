ul.breadcrumb {
    display: inline-block;
    margin: 0;
    list-style: none;
    li {
        display: inline;
    }
}

ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/";
}
ul.breadcrumb li:nth-child(2):before {
    content: "";
    padding: 4px;
}

ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
}
